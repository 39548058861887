<template>
	<NuxtSource
		v-for="format in withFormats"
		:src="src"
		:media="media"
		:modifiers="format.modifiers"
		:height="height"
		:width="width"
		:key="`${format.modifiers.format}-${src}`"
	/>
</template>

<script>
export default {
	props: {
		src: { type: String },
		media: { type: String },
		sizes: { type: String },
		height: { type: String },
		width: { type: String },
		modifiers: { type: Object, default: () => null },
	},
	computed: {
		nativeMime() {
			let suffix = this.src.split(".").pop();
			suffix = suffix.split("?")[0];
			return (
				{
					jpg: "jpeg",
				}[suffix] || suffix
			);
		},
		withFormats() {
			return ["webp", this.nativeMime].map((format) => {
				return {
					src: this.src,
					media: this.media,
					sizes: this.sizes,
					height: this.height,
					width: this.width,
					modifiers: {
						...this.modifiers,
						format,
					},
				};
			});
		},
	},
};
</script>
